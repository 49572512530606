import { IVoteData } from 'models/vote';
import { AppStateProvider } from 'store/index';

export function generatePayload(
  this: AppStateProvider,
  actionType: string,
  voteData?: IVoteData
) {
  const action_type = actionType;
  const apiKey = this.state.cmsData.settings.apiKey;
  const country = this.state.globalProps.userData.country;
  const device = this.state.globalProps.device;
  const user_id = this.state.globalProps.userData.email;
  const method = this.state.globalProps.userData.method;
  const state = this.state.globalProps.userData.state;
  const optIns = Object.keys(this.state.termsProps).reduce((storage, optIn) => {
    storage[`optin_${optIn}`] = this.state.termsProps[optIn].isChecked
      ? '1'
      : '0';
    return storage;
  }, Object.create(null));

  switch (actionType) {
    case 'get':
      return {
        action_type,
        apiKey,
        user_id,
        method,
        timestamp: new Date().getTime(),
      };
      break;
    case 'vote':
      return {
        action_type,
        apiKey,
        country,
        device,
        user_id,
        method,
        state,
        ...optIns,
        ...voteData,
        timestamp: new Date().getTime(),
      };
      break;
    default:
      // do nothing
      return null;
  }
}
