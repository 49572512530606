import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';

import App from 'components/app';
import { AppStateProvider } from 'store/index';
import history from 'util/history';
import './style.scss';

ReactDOM.render(
  <Router history={history}>
    <AppStateProvider>
      <Switch>
        <Route exact={true} path='/' component={App} />
        <Route path='/:name' component={App} />
      </Switch>
    </AppStateProvider>
  </Router>,

  document.getElementById('root') as HTMLElement
);
