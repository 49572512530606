import { css } from 'aphrodite/no-important';
import Terms from 'components/terms';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as fbHelpers from 'util/fb_helpers';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import { FacebookIcon } from 'util/icons';
import { storageFactory } from 'util/storage_helpers';
import { style } from './style';

const localStore = storageFactory(localStorage);

class Login extends React.Component<
  models.store.IAppState & models.login.ILoginSwap
> {
  public loaderTimeout: number;

  public state = {
    email: '',
    hasEmailError: false,
    hasFbPermissionsError: false,
    isLoading: false,
  };

  public componentWillMount(): void {
    const { email, method } = this.props.globalProps.userData;
    if (
      !this.state.hasFbPermissionsError &&
      method === constants.AUTH_METHODS.FACEBOOK &&
      !email
    ) {
      this.setState({ hasFbPermissionsError: true });
      this.props.loginFn.updateRetryFb(false);
    }
  }

  public componentDidUpdate(): void {
    if (
      this.state.hasFbPermissionsError &&
      this.props.loginProps.shouldRetryFb
    ) {
      this.props.loginFn.updateRetryFb(false);
      this._handleFacebookLogin();
    }
  }

  public componentWillUnmount(): void {
    clearTimeout(this.loaderTimeout);
  }

  private areButtonsEnabled(): boolean {
    return Object.keys(this.props.termsProps).every((term) => {
      return (
        (this.props.termsProps[term].isChecked &&
          this.props.termsProps[term].isRequired) ||
        !this.props.termsProps[term].isRequired
      );
    });
  }

  public render(): React.ReactNode {
    const { copy, settings, buttons } = this.props.cmsData.text.login;
    const areButtonsEnabled = this.areButtonsEnabled();

    const styles = style({
      globalStyles: this.props.stylesData.global,
      loginStyles: this.props.stylesData.login,
      buttonStyles: this.props.stylesData.login.buttons,
      hasEmailError: this.state.hasEmailError,
      areButtonsEnabled,
    });

    if (this.state.isLoading) {
      return <this.props.SwapLoading />;
    }

    if (
      this.state.hasFbPermissionsError &&
      !this.props.loginProps.shouldRetryFb
    ) {
      return <this.props.SwapError />;
    }

    return (
      <div aria-label='Log in' className={css(styles.login)}>
        <div className={css(styles.headlineContainer)}>
          <h2
            className={css(styles.headline)}
            dangerouslySetInnerHTML={{ __html: copy.headline }}
          />
          <p
            className={css(styles.description)}
            dangerouslySetInnerHTML={{ __html: copy.description }}
          />
        </div>

        {helpers.checkIfTrue(settings.display_facebook) &&
          helpers.checkIfTrue(settings.is_email_enabled) && (
            <h3>{copy.sign_in_options}</h3>
          )}

        {helpers.checkIfTrue(settings.display_facebook) && (
          <button
            aria-label='Sign in with Facebook'
            className={css(styles.button, styles.facebook_button)}
            disabled={!areButtonsEnabled}
            onClick={this._handleFacebookLogin}
          >
            <FacebookIcon />
            {buttons.facebook.copy}
          </button>
        )}

        {helpers.checkIfTrue(settings.display_facebook) &&
          helpers.checkIfTrue(settings.display_email) && (
            <div className={css(styles.divider)}>
              <div className={css(styles.divider_line)}></div>
              <div className={css(styles.divider_text)}>OR</div>
              <div className={css(styles.divider_line)}></div>
            </div>
          )}

        {helpers.checkIfTrue(settings.display_email) && (
          <form
            className={css(styles.form)}
            onSubmit={this._handleEmailLogin}
            noValidate
          >
            <div className={css(styles.formGroup)}>
              <label htmlFor='email-address' className={css(styles.inputLabel)}>
                {copy.email_label}
              </label>

              <input
                id='email-address'
                className={css(styles.email_input)}
                type='email'
                maxLength={40}
                name='email-address'
                onChange={this._handleInput}
                placeholder='Enter email'
              />

              <p
                className={css(styles.email_error)}
                role='alert'
                aria-live='assertive'
              >
                {copy.email_error}
              </p>
            </div>

            <Terms />

            <button
              aria-live='assertive'
              aria-label='Sign in with Email'
              className={css(styles.button, styles.email_button)}
              disabled={!areButtonsEnabled}
              type='submit'
            >
              {buttons.email.copy}
            </button>
            {this.props.loginProps.unauthorized && (
              <p className={css(styles.formError)}>{copy.unauthorized_error}</p>
            )}
          </form>
        )}
      </div>
    );
  }

  private _handleEmailLogin = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!this.state.email.match(constants.EMAIL_REGEX)) {
      return this.setState({ hasEmailError: true });
    }

    if (!this.areButtonsEnabled()) {
      return;
    }

    this.props.authFn.loginViaEmail(this.state.email);
    googleHelpers.trackGoogleEvent(
      constants.GA_CATEGORIES.BUTTON_CLICK,
      constants.GA_EVENTS.EMAIL_LOGIN,
      ''
    );
  };

  private _handleFacebookLogin = () => {
    this.props.loadingFn.setTitle('Processing...');
    this.props.loadingFn.setDescription('');
    this.setState({ isLoading: true });

    this._manageLoader();

    return window.FB.login(
      () => {
        this._resetLoader();

        googleHelpers.trackGoogleEvent(
          constants.GA_CATEGORIES.BUTTON_CLICK,
          constants.GA_EVENTS.FACEBOOK_LOGIN,
          ''
        );

        fbHelpers
          .checkLoginState()
          .then((response) => {
            if (!response.email) {
              return window.FB.logout(() => {
                this.setState({ hasFbPermissionsError: true });
              });
            }

            const payload = JSON.stringify({
              [response.email]: { ...this.props.termsProps },
            });
            localStore.setItem(constants.FACEBOOK_LOCALSTORAGE_LABEL, payload);
            this.props.authFn.loginViaFacebook(response);
          })
          .catch(() => {
            // Do nothing
          });
      },
      {
        auth_type: constants.FACEBOOK_AUTH_TYPES.REREQUEST,
        scope: this.props.cmsData.social.fb.scope,
      }
    );
  };

  private _handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      email: e.currentTarget.value.toLowerCase(),
      hasEmailError: false,
    });
  };

  private _manageLoader = () => {
    this.setState({ isLoading: true });

    this.props.loadingFn.setDescription('');

    this.loaderTimeout = window.setTimeout(() => {
      this.props.loadingFn.setDescription(
        'Facebook is taking too long to get your information'
      );
    }, 5000);
  };

  private _resetLoader = () => {
    this.setState({ isLoading: false });

    this.props.loadingFn.setTitle('');
    this.props.loadingFn.setDescription('');

    clearTimeout(this.loaderTimeout);
  };
}

export default Connect(Login);
