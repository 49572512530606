import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    error: {
      flexGrow: 1,
      ...styles.globalStyles.container,
      padding: '25px 20px',
      textAlign: 'center',
      ...styles.errorStyles.general,
    },

    headline: {
      fontSize: '24px',
      paddingBottom: '5px',
      ...styles.globalStyles.headlines,
      ...styles.errorStyles.headline,

      [media.tablet]: {
        ...styles.globalStyles.headlines.tablet,
        ...styles.errorStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.errorStyles.headline.desktop,
      },
    },

    description: {
      fontSize: '18px',
      ...styles.errorStyles.description,
      [media.tablet]: {
        ...styles.errorStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.errorStyles.description.desktop,
      },
    },

    link: {
      ...styles.errorStyles.link,
    },

    image: {
      width: '75%',
      margin: '0 auto 40px',
    },
  });
