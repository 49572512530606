import * as ReactGA from 'react-ga';
import * as constants from 'util/constants';
import { DOMUtil } from './dom';

interface GAEvent {
  type: string;
  payload: ReactGA.EventArgs;
}

interface GAPageView {
  type: string;
  payload: string;
}

let _isGoogleInitialized = false;
let _googleQueue: Array<GAEvent | GAPageView> = [];
const GA_LOCAL_STORAGE_KEY = 'ga:clientId';
const _trackerNames: string[] = [];

export const initializeGoogleAnalytics = (gaId: string) => {
  if (gaId && !_isGoogleInitialized) {
    const gaIds = gaId.split(',');
    const trackers: ReactGA.Tracker[] = [];
    const useLocalStorage = _shouldUseLocalStorage();
    gaIds.forEach((id, index) => {
      trackers.push({
        trackingId: id.trim(),
        gaOptions: {
          name: `tracker_${index}`,
          ...(useLocalStorage && {
            storage: 'none',
            clientId: localStorage.getItem(GA_LOCAL_STORAGE_KEY)
              ? (localStorage.getItem(GA_LOCAL_STORAGE_KEY) as string)
              : undefined,
          }),
        },
      });
      _trackerNames.push(`tracker_${index}`);
    });

    ReactGA.initialize(trackers);

    // If using localStorage, set clientId
    ReactGA.ga(() => {
      if (useLocalStorage) {
        const clientID = window.ga.getByName(_trackerNames[0]).get('clientId');
        localStorage.setItem(GA_LOCAL_STORAGE_KEY, clientID);
      }
    });

    _isGoogleInitialized = true;
  }

  if (_googleQueue.length > 0) {
    _googleQueue.forEach((call) => {
      switch (call.type) {
        case 'page':
          trackGooglePage(call.payload as string);
          break;
        case 'event':
          const { category, action, label } = call.payload as ReactGA.EventArgs;
          trackGoogleEvent(category, action, label);
          break;
        default:
        // do nothing
      }
    });

    _googleQueue = [];
  }
};

export const isInitialized = () => {
  return _isGoogleInitialized;
};

export const trackGoogleEvent = (
  category: string,
  action: string,
  label = ''
) => {
  if (isInitialized()) {
    if (_trackerNames.length) {
      ReactGA.event(
        { category: category, action: action, label: label },
        _trackerNames
      );
    } else {
      ReactGA.event({ category, action, label });
    }
  }
};

export const trackGooglePage = (page: string) => {
  if (isInitialized()) {
    if (_trackerNames.length) {
      ReactGA.pageview(page, _trackerNames);
    } else {
      ReactGA.pageview(page);
    }
  }
};

export const checkLinkClick = (evt: any) => {
  if (evt.target.tagName.toLowerCase() === 'a') {
    const category = constants.GA_CATEGORIES.LINK_CLICK;
    const action = evt.target.textContent + evt.target.href;
    const label = evt.target.textContent + evt.target.href;
    trackGoogleEvent(category, action, label);
  }
};

export const addLinkClickListener = () => {
  document.addEventListener('click', checkLinkClick, false);
};

export const removeLinkClickListener = () => {
  document.removeEventListener('click', checkLinkClick, false);
};

/**
 * Check if GA should use localStorage to store the clientID
 * @returns true if the application is inside an iframe and local storage is enabled, false otherwise.
 */
const _shouldUseLocalStorage = () => {
  return DOMUtil.isIframed() && DOMUtil.isLocalStorageAvailable();
};
