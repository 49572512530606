import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as helpers from 'util/helpers';
import { appMaxWidth, children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    footer: {
      backgroundColor: '#aaa',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      maxWidth: helpers.checkIfTrue(styles.settings.displayBleed)
        ? 'none'
        : appMaxWidth,
      width: '100%',
      minHeight: '40px',
      ...styles.footerStyles.general,

      [media.tablet]: {
        minHeight: '60px',
        ...styles.footerStyles.general.tablet,
      },

      [media.desktop]: {
        ...styles.footerStyles.general.desktop,
      },
    },

    container: {
      display: 'flex',
      alignItems: 'flex-start',
      flexGrow: 1,
      height: '100%',
      maxWidth: appMaxWidth,
      margin: 'auto',
      width: '100%',
      padding: '10px 20px',
      ...styles.footerStyles.container,

      [children('> * + *')]: {
        margin: '10px 0 0 0',
      },

      [media.tablet]: {
        paddingLeft: '30px',
        ...styles.footerStyles.container.tablet,
        [children('> * + *')]: {
          margin: 0,
        },
      },

      [media.desktop]: {
        paddingLeft: '12px',
        ...styles.footerStyles.container.desktop,
      },
    },

    footer_link: {
      margin: '0',
      padding: '0 32px 0 0',
      textDecoration: 'none',
      ...styles.footerStyles.link,

      [media.tablet]: {
        ...styles.footerStyles.link.tablet,
      },

      [media.desktop]: {
        ...styles.footerStyles.link.desktop,
      },

      ':hover': {
        color: darkenColor(styles.footerStyles.link.color),
      },

      ':focus': {
        color: darkenColor(styles.footerStyles.link.color),
      },
    },

    linksContainer: {
      display: 'flex',
      ...styles.footerStyles.linksContainer,

      [children('> * + *')]: {
        margin: '8px 0 0 0',
      },

      [media.tablet]: {
        ...styles.footerStyles.linksContainer.tablet,
        [children('> * + *')]: {
          margin: '0 0 0 32px',
        },
      },

      [media.desktop]: {
        ...styles.footerStyles.linksContainer.desktop,
      },
    },

    legal: {
      fontSize: '12px',
      ...styles.footerStyles.legal,

      [media.tablet]: {
        ...styles.footerStyles.legal.tablet,
      },

      [media.desktop]: {
        ...styles.footerStyles.legal.desktop,
      },
    },
  });
