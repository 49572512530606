import { css } from 'aphrodite/no-important';
import AdUnit from 'components/ad-unit/index';
import Closed from 'components/closed/index';
import Countdown from 'components/countdown/index';
import ErrorFbPermissions from 'components/error-fb-permissions/index';
import ErrorMessage from 'components/error-message/index';
import Footer from 'components/footer/index';
import Grid from 'components/grid/index';
import Header from 'components/header/index';
import Loading from 'components/loading/index';
import Login from 'components/login/index';
import Modal from 'components/modal/index';
import Panels from 'components/panels/index';
import Share from 'components/share/index';
import Terms from 'components/terms/index';
import Confirmation from 'components/thanks/index';
import User from 'components/user/index';
import Vote from 'components/vote/index';
import * as models from 'models/index';
import * as Pym from 'pym.js';
import * as React from 'react';
import { Connect } from 'store/index';
import api from 'util/api';
import * as cmsHelpers from 'util/cms-helpers';
import * as constants from 'util/constants';
import * as fbHelpers from 'util/fb_helpers';
import * as googleHelpers from 'util/google-helpers';
import {
  checkIfTrue,
  getDeviceData,
  getQSPs,
  insertFonts,
  normalizeForUrl,
} from 'util/helpers';
import history from 'util/history';
import { storageFactory } from 'util/storage_helpers';
import { style } from './style';

const localStore = storageFactory(localStorage);

class App extends React.Component<models.store.IAppState> {
  public timeoutCms: number;
  public timeoutStyles: number;
  public pymChild: any;

  public componentDidMount(): void {
    if (window.self !== window.top) {
      const qsps = getQSPs();
      const { childId } = qsps;

      const parentId = childId || 'telescope-widget';

      this.pymChild = new Pym.Child({
        polling: 500,
        id: parentId,
      });
    }

    api
      .fetchCms()
      .then((cmsData: models.api.ICmsData | null) => {
        if (cmsData) {
          const MAX_VOTES_PER_CONTESTANT = parseInt(
            cmsData.text.vote_settings.max_votes_per_contestant,
            10
          );
          const isMultiVote = MAX_VOTES_PER_CONTESTANT > 1;
          this.props.globalFn.setAppSettings({ isMultiVote });

          const fbLogin = checkIfTrue(
            cmsData.text.login.settings.display_facebook
          );
          const fbShare = checkIfTrue(
            cmsData.text.share.settings.display_facebook
          );
          const fbThanksShare = checkIfTrue(
            cmsData.text.thank_you.settings.display_share.display_facebook
          );

          if (fbLogin || fbShare || fbThanksShare) {
            fbHelpers.initializeFbSdk(cmsData.social.fb.id);
          }

          this.props.cmsFn.storeCmsData(cmsData);
          return cmsHelpers.checkGeo(cmsData);
        }
        return undefined;
      })
      .then(this.props.cmsFn.handleGeoData)
      .then(() => {
        if (this.props.cmsData.text) {
          return api.fetchStyles(
            this.props.cmsData.text.snapshot_settings.styles_widget_id
          );
        }
        return undefined;
      })
      .then((stylesData: models.api.ICmsData | null) => {
        if (stylesData) {
          this.props.cmsFn.storeStyles(stylesData);
        }
      })
      .then(() => {
        if (
          this.props.cmsProps.isAppReady &&
          this.props.cmsData.text &&
          this.props.cmsProps.inRegion
        ) {
          googleHelpers.initializeGoogleAnalytics(
            this.props.cmsData.settings.google_analytics
          );
          googleHelpers.addLinkClickListener();
          insertFonts(this.props.stylesData.global.font.fontImport);
          this._pollCms();
          this._pollStyles();
        }
        const device = getDeviceData();
        this.props.globalFn.setDevice(device);
        this._readLocalStore();
      })
      .then(() => {
        if (this.props.match.params.name) {
          this._displayContestant();
        }
      });
  }

  public componentWillUnmount(): void {
    clearTimeout(this.timeoutCms);
    clearTimeout(this.timeoutStyles);
    googleHelpers.removeLinkClickListener();
  }

  public render(): React.ReactNode {
    if (!this.props.cmsProps.isAppReady || !this.props.cmsData.text) {
      return <Loading />;
    }

    const { text, settings } = this.props.cmsData;
    const { stylesData } = this.props;

    const styles = style({
      pageStyles: this.props.stylesData.app,
      globalStyles: this.props.stylesData.global,
      modalOpen: this.props.modalProps.type !== '',
    });

    const ComposedLogin = (
      <Login SwapLoading={Loading} SwapError={ErrorFbPermissions}>
        <Terms />
      </Login>
    );

    const modalMap = {
      confirmation: (
        <Confirmation>
          <Share />
        </Confirmation>
      ),
      errorGeneric: (
        <ErrorMessage
          data={text.errors.generic}
          styles={stylesData.errors.generic}
        />
      ),
      errorOverlimit: (
        <ErrorMessage
          data={text.errors.overlimit}
          styles={stylesData.errors.overlimit}
        />
      ),
      errorWindow: (
        <ErrorMessage
          data={text.errors.window}
          styles={stylesData.errors.window}
        />
      ),
      login: ComposedLogin,
      vote: <Vote SwapLogin={ComposedLogin} />,
    };

    if (modalMap[this.props.modalProps.type] && this.pymChild) {
      const offset = settings.iframe_scroll_offset
        ? parseInt(settings.iframe_scroll_offset, 10)
        : 0;
      this.pymChild.scrollParentToChildPos(offset);
    }

    return (
      <div className={css(styles.page)}>
        <div
          className={css(styles.app_container)}
          aria-hidden={this.props.modalProps.type !== ''}
        >
          {checkIfTrue(text.ads.leaderboard.settings.display) && (
            <AdUnit size={constants.AD_UNITS.LEADERBOARD} />
          )}

          {checkIfTrue(text.ads.mobile_leaderboard.settings.display) && (
            <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD} />
          )}

          {checkIfTrue(text.header.settings.display) && (
            <Header>
              {checkIfTrue(text.ads.rectangle.settings.display) && (
                <AdUnit size={constants.AD_UNITS.RECTANGLE} />
              )}
              {checkIfTrue(text.ads.mobile_rectangle.settings.display) && (
                <AdUnit size={constants.AD_UNITS.MOBILE_RECTANGLE} />
              )}
            </Header>
          )}

          {this.renderContent()}

          {checkIfTrue(text.footer.settings.display) && <Footer />}
        </div>

        {modalMap[this.props.modalProps.type] && (
          <Modal>
            {modalMap[this.props.modalProps.type]}
            {checkIfTrue(text.ads.modal.settings.display) &&
              this.props.modalProps.type !== 'login' && (
                <AdUnit size={constants.AD_UNITS.MODAL} />
              )}
          </Modal>
        )}
      </div>
    );
  }

  renderContent = () => {
    const { text, settings } = this.props.cmsData;
    const { stylesData } = this.props;
    const isUserLoggedIn = this.props.globalProps.userData.isAuthorized;
    const displayGrid =
      (checkIfTrue(settings.window_status) ||
        checkIfTrue(text.closed.settings.display_grid)) &&
      isUserLoggedIn;

    const styles = style({
      globalStyles: stylesData.global,
      mainStyles: stylesData.main,
    });

    if (!this.props.cmsProps.inRegion) {
      return (
        <ErrorMessage
          data={text.errors.geo}
          styles={stylesData.errors.geo}
          track={constants.GA_PAGES.GEO}
        />
      );
    }

    return (
      <div className={css(styles.app)} role='main'>
        {isUserLoggedIn && <User />}

        {!checkIfTrue(settings.window_status) && (
          <Closed>
            {checkIfTrue(text.closed.settings.display_countdown) && (
              <Countdown key='countdown' />
            )}
          </Closed>
        )}

        {checkIfTrue(settings.window_status) && !isUserLoggedIn && (
          <Login></Login>
        )}
        {displayGrid && (
          <Grid>
            <Panels />
          </Grid>
        )}

        {checkIfTrue(text.ads.leaderboard_bottom.settings.display) && (
          <AdUnit size={constants.AD_UNITS.LEADERBOARD_BOTTOM} />
        )}

        {checkIfTrue(text.ads.mobile_leaderboard_bottom.settings.display) && (
          <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD_BOTTOM} />
        )}
      </div>
    );
  };

  _displayContestant = () => {
    const name = this.props.match.params.name.toLowerCase();

    let index = -1;
    this.props.globalProps.contestants.some((contestant: any, i: number) => {
      const contestantName = normalizeForUrl(contestant.name).toLowerCase();
      if (name === contestantName) {
        index = i;
        return true;
      }
      return false;
    });

    if (index === -1) {
      return history.push('/');
    }

    this.props.globalFn.setTargetIndex(index);
    this.props.modalFn.openModal(constants.MODAL_TYPES.vote);
  };

  _pollCms() {
    api
      .fetchCms()
      .then((cmsData: models.api.ICmsData | null) => {
        if (cmsData) {
          if (this.props.modalProps.type) {
            this.props.modalFn.closeModal();
          }
          this.props.cmsFn.storeCmsData(cmsData);
        }
      })
      .then(() => {
        let pollingRate = constants.MIN_POLLING_RATE;
        if (
          this.props.cmsData &&
          this.props.cmsData.text.snapshot_settings.polling_rate >=
            constants.MIN_POLLING_RATE
        ) {
          pollingRate = this.props.cmsData.text.snapshot_settings.polling_rate;
        }
        this.timeoutCms = window.setTimeout(
          this._pollCms.bind(this),
          pollingRate
        );
      });
  }

  _pollStyles() {
    api
      .fetchStyles(this.props.cmsData.text.snapshot_settings.styles_widget_id)
      .then((stylesData: models.api.ICmsData | null) => {
        if (stylesData) {
          this.props.cmsFn.storeStyles(stylesData);
        }
      })
      .then(() => {
        let pollingRate = constants.MIN_POLLING_RATE;
        if (
          this.props.cmsData &&
          this.props.stylesData.snapshotSettings.pollingRate >=
            constants.MIN_POLLING_RATE
        ) {
          pollingRate = this.props.stylesData.snapshotSettings.pollingRate;
        }
        this.timeoutStyles = window.setTimeout(
          this._pollStyles.bind(this),
          pollingRate
        );
      });
  }

  _readLocalStore = () => {
    if (localStore.getItem(constants.EMAIL_LOCALSTORAGE_LABEL)) {
      const payload = JSON.parse(
        localStore.getItem(constants.EMAIL_LOCALSTORAGE_LABEL)
      );
      const email = Object.keys(payload)[0];
      this.props.termsFn.updateTerms(payload[email]);
      this.props.authFn.loginViaEmail(email);
    } else {
      fbHelpers
        .checkLoginState()
        .then((response) => {
          const payload = JSON.parse(
            localStore.getItem(constants.FACEBOOK_LOCALSTORAGE_LABEL)
          );
          const email = Object.keys(payload)[0];
          this.props.termsFn.updateTerms(payload[email]);
          return this.props.authFn.loginViaFacebook(response);
        })
        .catch(() => {
          // Do nothing
        });
    }
  };
}

export default Connect(App);
