import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    login: {
      display: 'flex',
      flexDirection: 'column',
      ...styles.loginStyles.container,

      [media.tablet]: {
        ...styles.loginStyles.container.tablet,
      },

      [media.desktop]: {
        ...styles.loginStyles.container.desktop,
      },

      [children('> *')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      },
    },
    headlineContainer: {
      maxWidth: 520,
      marginBottom: 50,
    },
    form: {
      maxWidth: 320,
    },

    formError: {
      color: '#C1001A',
      fontSize: 14,
      fontWeight: 700,
    },

    headline: {
      textAlign: 'center',
      paddingBottom: '15px',
      ...styles.globalStyles.headlines,
      ...styles.loginStyles.headline,

      [media.tablet]: {
        paddingBottom: '50px',
        ...styles.globalStyles.headlines.tablet,
        ...styles.loginStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.loginStyles.headline.desktop,
      },
    },

    description: {
      textAlign: 'center',
      paddingBottom: '15px',
      fontWeight: 600,
      ...styles.loginStyles.description,

      [media.tablet]: {
        paddingBottom: '50px',
        ...styles.loginStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.loginStyles.description.desktop,
      },
    },

    button: {
      fontSize: '16px',
      margin: '10px auto',
      opacity: styles.areButtonsEnabled ? 1 : 0.2,
      padding: '8px',
      pointerEvents: styles.areButtonsEnabled ? 'auto' : 'none',
      width: '100%',
      minHeight: '40px',
    },

    email_button: {
      ...styles.buttonStyles.email,

      ':hover': {
        background: darkenColor(styles.buttonStyles.email.background),
      },

      ':focus': {
        background: darkenColor(styles.buttonStyles.email.background),
      },

      [media.tablet]: {
        ...styles.buttonStyles.email.tablet,

        ':hover': {
          background: styles.buttonStyles.email.tablet
            ? darkenColor(styles.buttonStyles.email.tablet.background)
            : '',
        },

        ':focus': {
          background: styles.buttonStyles.email.tablet
            ? darkenColor(styles.buttonStyles.email.tablet.background)
            : '',
        },
      },

      [media.desktop]: {
        ...styles.buttonStyles.email.desktop,

        ':hover': {
          background: styles.buttonStyles.email.desktop
            ? darkenColor(styles.buttonStyles.email.desktop.background)
            : '',
        },

        ':focus': {
          background: styles.buttonStyles.email.desktop
            ? darkenColor(styles.buttonStyles.email.desktop.background)
            : '',
        },
      },
    },

    facebook_button: {
      lineHeight: '1',
      ...styles.buttonStyles.facebook,

      [children('svg')]: {
        fill: '#fff',
        height: '20px',
        transform: 'translateY(1px)',
      },

      [children('> span')]: {
        display: 'inline-block',
        marginRight: '15px',
        verticalAlign: 'text-top',
      },

      ':hover': {
        background: darkenColor(styles.buttonStyles.facebook.background),
      },

      ':focus': {
        background: darkenColor(styles.buttonStyles.facebook.background),
      },
    },

    divider: {
      display: 'flex',
      alignItems: 'center',
      margin: '20px 0 24px',
    },

    divider_line: {
      flexGrow: 1,
      height: '1px',
      backgroundColor: '#ccc',
    },

    divider_text: {
      padding: '0 15px',
      fontWeight: 'bold',
      fontSize: '16px',
    },

    email_input: {
      border: '1px solid #bbb',
      borderColor: styles.hasEmailError
        ? styles.loginStyles.error.color
        : '#bbb',
      fontSize: '16px',
      height: '36px',
      padding: '0 10px',
      width: '100%',
      color: styles.hasEmailError ? styles.loginStyles.error.color : '#FFF',
      marginBottom: 10,
    },

    email_error: {
      color: '#D0021B',
      display: styles.hasEmailError ? 'block' : 'none',
      fontSize: '13px',
      margin: '10px auto',
      textAlign: 'left',
      ...styles.loginStyles.error,
    },

    inputLabel: {
      display: 'block',
      marginBottom: 5,
      ...styles.loginStyles.emailLabel,
    },
  });
