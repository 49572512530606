/* tslint:disable:no-shadowed-variable */
import { css } from 'aphrodite/no-important';
import Button from 'components/button';
import Video from 'components/video';
import * as models from 'models/index';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Connect } from 'store/index';
import { useVoteAttempt } from 'store/vote';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';
import history from 'util/history';
import { LeftArrowIcon, MinusIcon, PlusIcon, RightArrowIcon } from 'util/icons';
import { style } from './style';

type VoteProps = models.store.IAppState &
  models.vote.IVoteSwap &
  models.global.IGenericProps;

function Vote(props: VoteProps) {
  const MAX_CONTESTANT_VOTES = parseInt(
    props.cmsData.text.vote_settings.max_votes_per_contestant,
    10
  );
  const MAX_TOTAL_VOTES = parseInt(
    props.cmsData.text.vote_settings.max_votes_total,
    10
  );
  const { isMultiVote } = props.globalProps.appSettings;
  const defaultVoteCount = isMultiVote ? 0 : 1;
  const { voteAttempt, setVoteAttempt, isUserValid } = useVoteAttempt(
    props.globalProps.userData
  );
  const { contestantIndex } = props.globalProps;
  const contestant = props.globalProps.contestants[contestantIndex];
  const { copy, buttons } = props.cmsData.text.confirmation;
  const contestantVotes = props.voteProps.contestantVotes[contestant.id] || 0;
  const totalContestants = props.globalProps.contestants.length;
  const [voteCount, setVoteCount] = useState(contestantVotes);
  const isEliminated = helpers.checkIfTrue(contestant.is_eliminated);
  const isWindowOpen = helpers.checkIfTrue(
    props.cmsData.settings.window_status
  );
  const styles = style({
    globalStyles: props.stylesData.global,
    voteStyles: props.stylesData.confirmation,
  });

  // Set vote counts if the contestant changes
  useEffect(() => {
    const previouslySubmittedVoteCount =
      props.voteProps.contestantVotes[contestant.id] || 0;

    // If it's not a multivote, we automatically increment
    // the allocated votes by the default vote (usually 1)
    const voteCount = isMultiVote
      ? previouslySubmittedVoteCount
      : previouslySubmittedVoteCount + defaultVoteCount;

    setVoteCount(voteCount);
  }, [contestantIndex, isMultiVote]);

  useEffect(() => {
    const { contestantIndex } = props.globalProps;
    const contestantName = helpers.normalizeForUrl(
      props.globalProps.contestants[contestantIndex].name
    );
    history.push(`/${contestantName}`);

    document.addEventListener('keydown', _handleKeyDown);

    if (voteAttempt && isUserValid) {
      props.voteFn.submitVote({ id: contestant.id, voteCount });
    }

    return () => {
      document.removeEventListener('keydown', _handleKeyDown);
      history.push('/');
    };
  }, [voteAttempt]);

  if (voteAttempt && !isUserValid) {
    return props.SwapLogin;
  }

  return (
    <section
      className={css(styles.vote)}
      aria-label='Confirm your vote'
      aria-live='assertive'
    >
      <div className={css(styles.media_container)}>
        {contestant.image && !contestant.video && (
          <img src={contestant.image} alt={contestant.name} />
        )}

        {contestant.video && (
          <div className={css(styles.video)}>
            <div className={css(styles.video_wrapper)}>
              <Video url={contestant.video} />
            </div>
          </div>
        )}
      </div>

      <div className={css(styles.info_container)}>
        {contestant.nominee_headline && (
          <h1 className={css(styles.headline)}>
            {' '}
            {contestant.nominee_headline}{' '}
          </h1>
        )}

        {!contestant.nominee_headline && copy.universal_headline && (
          <p className={css(styles.headline)}> {copy.universal_headline} </p>
        )}

        {contestant.name && (
          <p className={css(styles.name)}>{contestant.name}</p>
        )}

        {contestant.description_1 && (
          <p className={css(styles.description_1)}>
            {contestant.description_1}
          </p>
        )}

        {contestant.description_2 && (
          <p className={css(styles.description_2)}>
            {contestant.description_2}
          </p>
        )}

        {contestant.description_3 && (
          <p className={css(styles.description_3)}>
            {contestant.description_3}
          </p>
        )}
      </div>

      {isMultiVote && isWindowOpen && !isEliminated && (
        <div className={css(styles.vote_buttons_wrapper)}>
          <button
            className={css(styles.vote_button, styles.vote_button_minus)}
            onClick={() => _handleVotesUpdate(-1)}
            aria-disabled={_isDisabled('DECREMENT')}
            aria-label='subtract vote'
          >
            <MinusIcon />
          </button>

          <p
            className={css(styles.vote_counter)}
            aria-readonly='true'
            role='presentation'
            aria-hidden='true'
          >
            {voteCount}
          </p>

          <button
            className={css(styles.vote_button, styles.vote_button_plus)}
            onClick={() => _handleVotesUpdate(1)}
            aria-disabled={_isDisabled('INCREMENT')}
            aria-label='add vote'
          >
            <PlusIcon />
          </button>
        </div>
      )}
      <div className={css(styles.cta_container)}>
        {contestant.link_url && (
          <a
            href={contestant.link_url}
            target='_blank'
            className={css(styles.link)}
          >
            {' '}
            {copy.link}{' '}
          </a>
        )}

        {isWindowOpen && !isEliminated && (
          <Button
            aria-label='Submit Votes'
            buttonData={buttons.vote}
            buttonStyles={props.stylesData.confirmation.buttons.vote}
            options={{ globalStyles: props.stylesData.global.buttons }}
            onClick={() => _handleSubmit()}
            isDisabled={_isDisabled('SUBMIT_VOTE')}
          />
        )}
      </div>

      {props.children}

      {totalContestants > 1 && (
        <>
          <button
            aria-label='Previous contestant'
            className={css(styles.nav_arrow, styles.nav_prev)}
            onClick={() => _scrollTargetIndex('left')}
          >
            <LeftArrowIcon />
          </button>

          <button
            aria-label='Next contestant'
            className={css(styles.nav_arrow, styles.nav_next)}
            onClick={() => _scrollTargetIndex('right')}
          >
            <RightArrowIcon />
          </button>
        </>
      )}
    </section>
  );

  function _handleSubmit() {
    // aria-disabled won't stop click events from being fired, so need to check here
    if (_isDisabled('SUBMIT_VOTE')) {
      return false;
    }

    if (!isUserValid) {
      return setVoteAttempt({ voteAttempt: true });
    }

    setVoteAttempt({ voteAttempt: false });
  }

  function _handleKeyDown(e: any) {
    switch (e.keyCode) {
      case constants.KEYS.LEFT:
        _scrollTargetIndex('left');
        break;

      case constants.KEYS.RIGHT:
        _scrollTargetIndex('right');
        break;

      default:
        break;
    }
  }

  function _scrollTargetIndex(direction: string) {
    const length = props.globalProps.contestants.length;

    let { contestantIndex } = props.globalProps;
    contestantIndex += direction === 'left' ? -1 : 1;
    contestantIndex = (contestantIndex + length) % length;

    props.globalFn.setTargetIndex(contestantIndex);

    const contestantName = helpers.normalizeForUrl(contestant.name);
    history.push(`/${contestantName}`);
  }

  function _handleVotesUpdate(value: number) {
    const newVotes = voteCount + value;
    const isValidVote = _isValidVote(newVotes);

    const allowIncrement = helpers.checkIfTrue(
      props.cmsData.text.vote_settings.allow_multivote_changes.allow_increment
    );
    const allowDecrement = helpers.checkIfTrue(
      props.cmsData.text.vote_settings.allow_multivote_changes.allow_decrement
    );
    const disableIncrementButton =
      value > 0 && !allowIncrement && newVotes > contestantVotes;
    const disableDecrementButton =
      value < 0 && !allowDecrement && newVotes < contestantVotes;

    if (disableIncrementButton || disableDecrementButton) {
      return;
    }

    if (!isUserValid) {
      setVoteAttempt(true);
      return;
    }
    if (!isValidVote) {
      return;
    }

    setVoteCount(newVotes);
  }

  function _isValidVote(count: number): boolean {
    const totalVotesRemaining = _getTotalVotesRemaining(count);

    if (count > MAX_CONTESTANT_VOTES) {
      return false;
    }
    if (count < 0) {
      return false;
    }
    if (totalVotesRemaining < 0) {
      return false;
    }

    return true;
  }

  function _getTotalVotesRemaining(currentVotes = voteCount) {
    const votesAlreadySubmitted = props.voteProps.totalVotes;
    const unsubmittedVotes = contestantVotes - currentVotes;

    return MAX_TOTAL_VOTES - votesAlreadySubmitted + unsubmittedVotes;
  }

  function _isDisabled(button: string) {
    const votesRemaining = _getTotalVotesRemaining();
    const allowIncrement = helpers.checkIfTrue(
      props.cmsData.text.vote_settings.allow_multivote_changes.allow_increment
    );
    const allowDecrement = helpers.checkIfTrue(
      props.cmsData.text.vote_settings.allow_multivote_changes.allow_decrement
    );
    const disableIncrementButton =
      !allowIncrement && voteCount >= contestantVotes;
    const disableDecrementButton =
      !allowDecrement && voteCount <= contestantVotes;

    switch (button) {
      case 'SUBMIT_VOTE':
        return (
          voteCount === contestantVotes ||
          votesRemaining < 0 ||
          voteCount - 1 === MAX_CONTESTANT_VOTES
        );
      case 'INCREMENT':
        return (
          voteCount === MAX_CONTESTANT_VOTES ||
          votesRemaining <= 0 ||
          disableIncrementButton
        );
      case 'DECREMENT':
        return voteCount === 0 || disableDecrementButton;
      default:
        return false;
    }
  }
}

export default Connect(Vote);
