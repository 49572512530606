import * as models from 'models/index';
import api from 'util/api';
import helpers from 'util/helpers';

export const checkGeo = (
  cmsData: models.api.ICmsData
): Promise<null | models.store.IRegionCheck> => {
  let isWhitelist = true;
  /**
   * KIA-12: If the list begins with a dash, then
   * treat the list like a blacklist.
   * For example, "-CN,HK" means a worldwide vote
   * that excludes China and Hong Kong.
   */
  if (cmsData.settings.countries.trim().startsWith('-')) {
    isWhitelist = false;
  }

  const countries = cmsData.settings.countries
    .replace(/ /g, '')
    .replace(/-/g, '')
    .split(',')
    .filter((item: string) => item.length);

  if (!countries.length) {
    return helpers.Deferred().resolve(null);
  }

  return api.fetchGeo().then((geoData: models.api.IGeoData) => {
    return {
      userCountry: geoData.geoheaders.country,
      userRegion: geoData.geoheaders.region,
      countries: countries,
      isWhitelist,
    };
  });
};

export const checkIfInRegion = (data: null | models.store.IRegionCheck) => {
  if (!data) {
    return;
  }
  const { userCountry, countries, isWhitelist } = data;

  return isWhitelist
    ? countries.indexOf(userCountry) >= 0
    : countries.indexOf(userCountry) === -1;
};
