import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, media } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    closed: {
      textAlign: 'center',
      flexGrow: '1',
      marginBottom: '20px',
      ...styles.globalStyles.container,

      [media.tablet]: {
        ...styles.globalStyles.container.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.container.desktop,
      },
    },

    headline: {
      ...styles.globalStyles.headlines,
      ...styles.closedStyles.headline,

      [media.tablet]: {
        fontSize: '45px',
        paddingBottom: '2px',
        ...styles.globalStyles.headlines.tablet,
        ...styles.closedStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.closedStyles.headline.desktop,
      },
    },

    description: {
      paddingBottom: '26px',
      ...styles.closedStyles.description,

      [media.tablet]: {
        fontSize: '20px',
        paddingBottom: '19px',
        ...styles.closedStyles.description.tablet,
      },

      [media.desktop]: {
        paddingBottom: '44px',
        ...styles.closedStyles.description.desktop,
      },
    },

    video_wrapper: {
      position: 'relative',
      width: '100%',
      height: '0',
      paddingBottom: '56.25%',

      [children('iframe')]: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
      },

      [media.desktop]: {
        width: '640px',
        height: '360px',
        paddingBottom: '0',
        margin: 'auto',
      },
    },
  });
