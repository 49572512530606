import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import * as React from 'react';
import { Connect } from 'store/index';
import { style } from './style';

class Header extends React.Component<models.store.IAppState> {
  render() {
    const { copy, logo } = this.props.cmsData.text.header;
    const { stylesData } = this.props;
    const { isAuthorized } = this.props.globalProps.userData;

    const styles = style({
      globalStyles: stylesData.global,
      headerStyles: stylesData.header,
      settings: { ...stylesData.header.settings },
    });

    return (
      <header className={css(styles.header)} role='banner'>
        <div className={css(styles.container)}>
          {logo.image && logo.link && (
            <a className={css(styles.logo)} target='_blank' href={logo.link}>
              <img alt={logo.alt_text} src={logo.image} />
            </a>
          )}
          {logo.image && !logo.link && (
            <div className={css(styles.logo)}>
              <img alt={logo.alt_text} src={logo.image} />
            </div>
          )}

          <div className={css(styles.ad)}>{this.props.children}</div>

          {(copy.headline || copy.description) && isAuthorized && (
            <div className={css(styles.copy_wrapper)}>
              {copy.headline && (
                <h1 className={css(styles.headline)}>{copy.headline}</h1>
              )}

              {copy.description && (
                <p className={css(styles.description)}>{copy.description}</p>
              )}
            </div>
          )}
        </div>
      </header>
    );
  }
}

export default Connect(Header);
