import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { appMaxWidth } from 'util/style-helpers';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    app_container: {
      backgroundColor: '#fff',
      height: 'auto',
      minHeight: window.top !== window.self ? 'auto' : '100vh',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'flex-start',
      zIndex: 0,
      position: 'relative',
      pointerEvents: styles.modalOpen ? 'none' : 'normal',
      ...styles.globalStyles.font,
      ...styles.pageStyles,
    },

    app: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      margin: '0 auto',
      maxWidth: appMaxWidth,
      width: '100%',
      paddingBottom: '20px',
      ...styles.mainStyles,
    },

    page: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
    },
  });
